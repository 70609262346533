import React from 'react';
import Layout from '../components/Layout'
import Meta from '../components/Meta'
import Service from '../components/Services/Service'
import PageHeading from '../components/PageHeading'
import archImg from '../assets/arch.svg'
import fullstackImg from '../assets/full_stack.svg'
import devopsImg from '../assets/devops.svg'
import troubleshootImg from '../assets/troubleshoot.svg'
import serviceBgImg from '../assets/servicebg.jpg'
import serviceSymbolImg from '../assets/service.svg'

const META_DESCRIPTION = 'Torquedev specializes in a few services, which produce great products when mixed together. Cloud-native architecture, full-stack with React/Node, and DevOps are our key areas.'

const services = [
  {
    id: 'architecture',
    name: 'Software Architecture',
    description: 'We are passionate about creating robust and well-built architectural designs. Before conceiving our designs, we gather the necessary requirements to ensure that expectations such as scalability & operational cost are met, once the implementation is complete. Our specialty is cloud-native, containerized, microservice applications, which strongly attempt to be simple in terms of complexity. When implementation time and cost is a concern, we embrace trusted third-party managed services to perform some heavy lifting.',
    img: archImg
  },
  {
    id: 'full-stack',
    name: 'Full-Stack Development',
    description: 'The React/Node/SQL stack is our staple, but we also have experience with other web stacks. We focus on creating clean and maintainable project structures and code, to allow for successful project handoffs for your clients. For each milestone in the development cycle, we will gather feedback and requirements, to ensure the product stays in line with business goals. When working with existing code bases, we always take the opportunity to improve code quality alongside feature implementation. We communicate well with external UI/UX professionals to ensure the application delivers a great experience for users.',
    img: fullstackImg,
    platformMargin: '-5vw'
  },
  {
    id: 'devops',
    name: 'DevOps',
    description: 'Efficient developer/deployment processes keep both the business and the developers happy. Automating application deployment reduces risk and allows more time for developers to do what they do best. When building cloud-native applications, we strongly believe in keeping the cloud infrastructure as code, to make new deployments of an application simple and straightforward. Continuous integration systems are provided to ensure code changes are deployed to one or more environments without human intervention.',
    img: devopsImg,
    platformMargin: '-8vw'
  },
  {
    id: 'troubleshooting',
    name: 'Code Troubleshooting',
    description: 'Found a notorious bug that has proved hard to fix? Let us take a stab at it! Tracking down a hard bug involves asking the right questions about it. Like a doctor, we will ask about symptoms and perform the adequate testing, with the hope of concluding with a diagnosis. In regards to treatment, we will explain and provide all the methods of solving the bug, along with pros and cons of each solution.',
    img: troubleshootImg,
    platformMargin: '3vw'
  }
]

export default function Services() {
  return (
    <Layout>
      <Meta pageName='Services' description={META_DESCRIPTION} />
      <PageHeading bgImg={serviceBgImg} symbolImg={serviceSymbolImg} title='Our Services' />
      {services.map((v, i) => (
        <Service {...v} key={i} isAlt={i % 2 === 1} />
      ))}
    </Layout>
  );
}
