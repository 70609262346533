import React, { useRef } from 'react'
import styles from './index.module.css'
import { c, useScrollEv } from '../../../util'

export default function Service({ id, name, description, img, platformMargin }) {

  const rootRef = useRef(null);

  const isIn = useScrollEv(300, (scroll) => (scroll > (rootRef.current.offsetTop - (window.innerHeight * 0.6))));

  return (
    <div id={id} ref={rootRef} className={c([styles.root])}>
      <div className={c([styles.platform, isIn && styles.platformIn])} style={{ marginTop: platformMargin }}></div>
      <img src={img} alt={name} className={c([styles.img, isIn && styles.imgIn])} />
      <div className={c([styles.content, isIn && styles.contentIn])}>
        <h2>{name}</h2>
        <div>{description}</div>
      </div>
    </div>
  )
}
